// @ts-nocheck
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { Button, Input, message } from 'antd';
import Image from 'next/image';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useFm } from '@better-bit-fe/base-hooks';
import Email from '~/components/emailLogin';
import EmailH5 from '~/components/emailLogin/indexH5';
import { showConnectDialog } from '@better-bit-fe/betterbit-frame';
import { basePath, ENV, isMobile } from '@better-bit-fe/base-utils';
import { useUserInfo } from '@better-bit-fe/base-provider';
import metamaskImage from '~/public/images/metamask.svg?url';
import metamaskImageDark from '~/public/images/metamask-dark.svg?url';
import emailImage from '~/public/images/email.svg';
import telegramImage from '~/public/images/telegram.svg';
import { bot_id, STORAGE_ADDRESS, ISNEWUSER } from '~/constants';
import { postTgLogin, postProfileUpdate, getBotId } from '~/api';
import { useParticle } from '~/hooks/useParticle';
import WalletLoading from '~/components/walletLoading';
import { loginPushRouter } from '~/utils';
import queryString from 'query-string';
import Style from './index.module.less';

interface ILoginContentProps {
  handleShowBindEmail: (autoRedirect?: boolean) => void;
}

const LoginContent: React.FC<ILoginContentProps> = (props) => {
  const t = useFm();
  const { handleShowBindEmail } = props;
  const [currentLogin, setcurrentLogin] = useState<string>('');
  const [loading, setloading] = useState<boolean>(false);
  const [errorConnect, seterrorConnect] = useState<boolean>(false);
  const [referralCode, setreferralCode] = useState<string>('');
  const [curBotId, setcurBotId] = useState<string>('');
  const [walletDetail, setwalletDetail] = useState({
    visible: false,
    address: ''
  });
  const router = useRouter();
  const emailRef = useRef();
  const isMb = useMemo(() => isMobile(), []);
  const { isLogin, updateUserInfo, userInfo } = useUserInfo();
  const { connect, getAddress } = useParticle();
  const [isDisableReferral, setIsDisableReferral] = useState(false);
  const web2LoginPart = [
    {
      id: 'email',
      // title: 'Email',
      title: t('login-email'),
      icon: emailImage
    },
    {
      id: 'telegram',
      // title: 'Telegram',
      title: t('login-tg'),
      icon: telegramImage
    }
  ];
  const wed3part = [
    {
      id: 'metamask',
      img: isMb ? metamaskImageDark : metamaskImage,
      // name: 'MetaMask'
      name: t('metamask-title')
    }
  ];

  const changeWalletDetail = (value: { visible: boolean; address: string }) => {
    setwalletDetail(value);
  };

  const getWalletDetail = async () => {
    const address = await getAddress();
    if (address) {
      setwalletDetail({ ...walletDetail, address });
    }
  };

  const web3Login = useCallback(
    (type: string) => {
      if (isMb) return;
      setcurrentLogin(type);
      if (type === 'metamask') {
        // metamask
        if (referralCode) {
          showConnectDialog({
            referral_code: referralCode
          });
        } else {
          showConnectDialog();
        }
      }
    },
    [isMb, referralCode]
  );

  const tgAuth = useCallback(() => {
    try {
      if (typeof window !== 'undefined') {
        setloading(true);
        window?.Telegram?.Login?.auth(
          {
            // bot_id: bot_id[ENV],
            bot_id: curBotId || bot_id[ENV],
            request_access: true
          },
          async (data) => {
            if (!data) {
              setloading(false);
              return;
            }
            const params = referralCode
              ? {
                  referral_code: referralCode,
                  bot_id: curBotId || bot_id[ENV],
                  ...data
                }
              : { bot_id: curBotId || bot_id[ENV], ...data };
            const loginData = await postTgLogin(params);
            const { is_new_user, particle_token } = loginData;
            localStorage.setItem(ISNEWUSER, is_new_user);
            localStorage.setItem(
              STORAGE_ADDRESS,
              JSON.stringify({
                address: '',
                walletName: 'telegram',
                particle_token
              })
            );
            const newUserInfo = await updateUserInfo();
            const isWalletLoadingOpen =
              is_new_user ||
              // previous unipass users
              (newUserInfo?.address && newUserInfo.wallet_type === 2) ||
              !newUserInfo?.address;
            if (isWalletLoadingOpen) {
              changeWalletDetail({
                visible: true,
                address: walletDetail.address
              });
              setloading(false);
            }
            if (newUserInfo?.wallet_type === 3) {
              const _particleAddress = newUserInfo?.address;
              if (!isMb) {
                setTimeout(() => {
                  connect({ jwt: particle_token });
                }, 0);
              }
              changeWalletDetail({
                visible: isWalletLoadingOpen,
                address: _particleAddress
              });
            } else {
              // connect particle
              if (!isMb) {
                await connect({ jwt: particle_token });
                const _particleAddress = await getAddress();
                changeWalletDetail({
                  visible: isWalletLoadingOpen,
                  address: _particleAddress
                });
              }
            }
            setloading(false);

            message.success(t('loginTips'));
          }
        );
      }
    } catch (error) {
      setloading(false);
      seterrorConnect(true);
      console.log(error, 'error');
    }
  }, [curBotId, referralCode, updateUserInfo]);

  const web2Login = useCallback(
    (type: string) => {
      setcurrentLogin(type);
      if (type === 'email') {
        if (isMb) {
          emailRef.current.changeModalVisible(true);
        }
      } else if (type === 'telegram') {
        tgAuth();
      }
    },
    [tgAuth]
  );

  const switchRender = useCallback(
    (type: '' | 'email' | 'telegram' | 'metamask') => {
      if (type === 'email')
        return (
          <Email
            referralCode={referralCode}
            walletDetail={walletDetail}
            changeWalletDetail={changeWalletDetail}
          />
        );
      if (type === 'telegram')
        return (
          <div className={Style.iconContainer}>
            <Image
              src={basePath + '/images/telegram-big.svg'}
              alt={'telegram'}
              width={72}
              height={72}
            />
            <div>Telegram</div>
            {loading ? (
              <div>
                <LoadingOutlined style={{ color: '#26F2D1' }} />
                <span className={Style.connecting}>
                  {t('connectionWait-status')}
                </span>
              </div>
            ) : null}
            {errorConnect ? (
              <div>
                <div className={Style.errorContent}>
                  {t('connectionFail-status')}
                </div>
                <Button type="primary" onClick={() => web2Login(type)}>
                  {t('tryAgain-btn')}
                </Button>
              </div>
            ) : null}
          </div>
        );
      if (type === 'metamask')
        return (
          <div className={Style.iconContainer}>
            <Image
              src={basePath + '/images/metamask-big.svg'}
              alt={'metamask'}
              width={72}
              height={72}
            />
            <div>Metamask</div>
          </div>
        );
      return (
        <div>
          {/* <div>
            <div className={Style.title}>
              <span>1</span>
              <span>{t('content-title-1')}</span>
            </div>
            <p className={Style.content}>{t('content-1')}</p>
          </div> */}
          <div className={Style.part2}>
            <div className={Style.title}>
              {/* <span>2</span> */}
              <span>{t('content-title-2')}</span>
            </div>
            <div className={Style.contentContainer}>
              <Image
                src={basePath + '/images/Icon-1.svg'}
                alt={'mufex-icon1'}
                width={42}
                height={42}
              />
              <div>
                <div className={Style.subtitle}>
                  {t('content-2-subtitle-1')}
                </div>
                <p className={Style.content}>{t('content-2-subcontent-1')}</p>
              </div>
            </div>
            <div className={Style.contentContainer}>
              <Image
                src={basePath + '/images/Icon-2.svg'}
                alt={'mufex-icon2'}
                width={42}
                height={42}
              />
              <div>
                <div className={Style.subtitle}>
                  {t('content-2-subtitle-2')}
                </div>
                <p className={Style.content}>{t('content-2-subcontent-2')}</p>
              </div>
            </div>
            <div className={Style.contentContainer}>
              <Image
                src={basePath + '/images/Icon-3.svg'}
                alt={'mufex-icon3'}
                width={42}
                height={42}
              />
              <div>
                <div className={Style.subtitle}>
                  {t('content-2-subtitle-3')}
                </div>
                <p className={Style.content}>{t('content-2-subcontent-3')}</p>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [errorConnect, loading, t, web2Login]
  );

  const botIdGet = async () => {
    const botIdList = await getBotId();
    const domain = window?.location?.hostname;
    Object.entries(botIdList).forEach((item) => {
      if (domain.includes(item[0])) {
        setcurBotId(item[1]);
        return;
      }
    });
  };

  useEffect(() => {
    console.log(ENV, 'ENV');
    if (
      (walletDetail.address && isLogin && userInfo && !walletDetail.visible) ||
      userInfo?.wallet_type === 1 ||
      (userInfo && !userInfo?.wallet_type && isMb)
    )
      handleShowBindEmail();
  }, [isLogin, userInfo, walletDetail]);

  useEffect(() => {
    if (userInfo && walletDetail.address) {
      if (userInfo?.address !== walletDetail.address) {
        postProfileUpdate({ address: walletDetail.address });
      }
    }
  }, [walletDetail, userInfo, userInfo?.address]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const referralCode =
      parsed?.invite_code ||
      parsed?.inviteCode ||
      new URLSearchParams(window.location.search).get('inviteCode') ||
      new URLSearchParams(window.location.search).get('invite_code') ||
      Cookie.get('invite_code') ||
      Cookie.get('inviteCode');
    console.log(
      '---invite_code or inviteCode: ' + Cookie.get('invite_code') ||
        Cookie.get('inviteCode')
    );
    console.log(
      '---search inviteCode: ' +
        new URLSearchParams(window.location.search).get('inviteCode') ||
        new URLSearchParams(window.location.search).get('invite_code')
    );
    if (referralCode) {
      setIsDisableReferral(true);
      setreferralCode(referralCode);
    } else {
      setIsDisableReferral(false);
    }
    botIdGet();
  }, []);

  useEffect(() => {
    if (userInfo) {
      getWalletDetail();
    }
  }, [userInfo]);

  return (
    <>
      {walletDetail?.visible ? (
        <WalletLoading
          walletAddress={walletDetail.address}
          handleShowBindEmail={handleShowBindEmail}
        />
      ) : (
        <div className={Style.loginContent}>
          <div className={Style.leftPart}>
            <div>
              <div className={Style.title}>{t('web2-title')}</div>
              {web2LoginPart.map((item, index) => (
                <div
                  className={Style.web2Item}
                  key={item.id}
                  onClick={() => web2Login(item.id)}
                >
                  <Image
                    src={item.icon}
                    alt={item.id}
                    width={isMb ? 48 : 28}
                    height={isMb ? 48 : 28}
                  />
                  <span>{item.title}</span>
                  {isMb ? (
                    <ArrowRightOutlined
                      width={24}
                      height={24}
                      className={Style.h5Btn}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div className={Style.middleLine}>
              <span></span>
              Or
              <span></span>
            </div>
            <div>
              <div className={Style.title}>{t('web3-title')}</div>
              {wed3part.map((item, index) => (
                <div
                  className={Style.loginItem}
                  key={item.id}
                  onClick={() => web3Login(item?.id)}
                >
                  <Image
                    src={item.img}
                    alt={item.name}
                    width={isMb ? 48 : 28}
                    height={isMb ? 48 : 28}
                  />
                  <span>{item.name}</span>
                  {isMb ? (
                    <ArrowRightOutlined
                      width={24}
                      height={24}
                      className={Style.h5Btn}
                      style={{ color: '#777' }}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div className={Style.referral}>
              <div className={Style.referralTitle}>
                {t('referralCode-title')}
              </div>
              <Input
                disabled={isDisableReferral}
                value={referralCode}
                placeholder={t('referralCode-inp')}
                className={Style.referralCodeInp}
                onChange={(Event) => setreferralCode(Event.target.value)}
              />
              <p className={Style.referralDesc}>{t('referralCode-desc')}</p>
            </div>
          </div>
          <div className={Style.rightPart}>
            {!isMb ? (
              switchRender(currentLogin)
            ) : (
              <div>
                {/* <div>
                  <div className={Style.title}>
                    <span>1</span>
                    <span>{t('content-title-1')}</span>
                  </div>
                  <p className={Style.content}>{t('content-1')}</p>
                </div> */}
                <div className={Style.part2}>
                  <div className={Style.title}>
                    {/* <span>2</span> */}
                    <span>{t('content-title-2')}</span>
                  </div>
                  <div className={Style.contentContainer}>
                    <Image
                      src={basePath + '/images/Icon-1.svg'}
                      alt={'mufex-icon1'}
                      width={42}
                      height={42}
                    />
                    <div>
                      <div className={Style.subtitle}>
                        {t('content-2-subtitle-1')}
                      </div>
                      <p className={Style.content}>
                        {t('content-2-subcontent-1')}
                      </p>
                    </div>
                  </div>
                  <div className={Style.contentContainer}>
                    <Image
                      src={basePath + '/images/Icon-2.svg'}
                      alt={'mufex-icon2'}
                      width={42}
                      height={42}
                    />
                    <div>
                      <div className={Style.subtitle}>
                        {t('content-2-subtitle-2')}
                      </div>
                      <p className={Style.content}>
                        {t('content-2-subcontent-2')}
                      </p>
                    </div>
                  </div>
                  <div className={Style.contentContainer}>
                    <Image
                      src={basePath + '/images/Icon-3.svg'}
                      alt={'mufex-icon3'}
                      width={42}
                      height={42}
                    />
                    <div>
                      <div className={Style.subtitle}>
                        {t('content-2-subtitle-3')}
                      </div>
                      <p className={Style.content}>
                        {t('content-2-subcontent-3')}
                      </p>
                    </div>
                  </div>
                </div>
                <EmailH5
                  ref={emailRef}
                  referralCode={referralCode}
                  walletDetail={walletDetail}
                  changeWalletDetail={changeWalletDetail}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LoginContent;
